@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes moveUp {
    from {
        transform: translateY(20px);
    }
    to {
        transform: translateY(0);
    }
}

.fade-in {
    animation: fadeIn 0.5s ease-out;
}

.fade-out {
    animation: fadeOut 0.5s ease-out;
}

.move-up {
    animation: moveUp 0.5s ease-out;
}

.fade {
    @apply fade-in;
}

body {
    @apply bg-base-100 text-base-content;
}

.tab-container {
    @apply overflow-hidden flex flex-col flex-1;
}

.hyperlink {
    @apply text-blue-500 hover:text-blue-700;
}

.firebase-emulator-warning {
    display: none;
}

.split-pane-container {
    position: absolute;
    top: 60px;
    bottom: 0px;
    left: 60px;
    right: 0px;
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
}

h1,
h2,
h3 {
    @apply font-bold;
}

h1 {
    @apply text-3xl;
}

h2 {
    @apply text-2xl;
}

h3 {
    @apply text-xl tracking-tighter font-bold;
}

h4 {
    @apply text-lg font-medium tracking-tight;
}

h5 {
    @apply tracking-tighter font-medium capitalize;
}

h6 {
    @apply text-sm tracking-tight font-medium capitalize;
}

p {
    @apply prose;
}

.opacity-100 {
    opacity: 1 !important;
}

.rating-badge.rated {
    @apply border rounded-full;
}

.tab.tab-active.bordered:not(.tab-disabled) {
    @apply border-primary;
}

.react-confirm-alert-overlay {
    @apply bg-base-500 bg-opacity-80;
}

label {
    @apply font-medium;
}

.page {
    @apply fade flex-1 w-full flex flex-col items-stretch overflow-auto bg-base-150 space-y-6;
}
.page-no-bg {
    @apply fade flex-1 w-full flex flex-col items-stretch overflow-auto space-y-6;
}
.page-padding {
    @apply px-8 py-6;
}

.link {
    @apply text-blue-500 hover:text-blue-700;
}

@layer components {
    .btn-sm:not(.btn-circle) {
        @apply h-10;
    }
    .btn-outline:hover {
        @apply bg-base-800 text-base-100 border-base-800;
    }
    .btn-outline.btn-secondary {
        @apply bg-base-100 text-neutral border-neutral;
    }
}

.scrollable {
    overflow: auto !important;
    overflow-x: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}

.side-area {
    @apply flex flex-col flex-1 justify-between h-full overflow-hidden;
}

ul {
    padding-left: 0;
}

.middle {
    @apply flex flex-col items-center justify-center;
}

.grid-item {
    @apply xs:col-span-12 sm:col-span-6 md:col-span-3 lg:col-span-2;
}

.grid-item-lg {
    @apply xs:col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3;
}

.toolbar {
    @apply sticky z-10 bg-base-150 top-0 flex items-center px-4 py-2;
}

.breadcrumb {
    display: flex;
}

.hide-scroll-bars {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scroll-bars::-webkit-scrollbar {
    display: none;
}

.dropdown-menu .dropdown-divider:last-of-type {
    display: none;
}

.dropdown-menu {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dropdown-item {
    text-transform: capitalize;
}

.rating-badge {
    height: 28px;
    width: 28px;
    padding: 4px;
    font-weight: 600;
}

.__react_component_tooltip {
    max-width: 180px;
    text-align: center;
    text-transform: capitalize;
}

.tooltip {
    text-transform: capitalize;
}

.react-confirm-alert-body {
    @apply font-medium;
}

.react-confirm-alert-body button {
    @apply btn btn-sm;
}

.react-confirm-alert-button-group {
    @apply flex;
}

.react-confirm-alert-body h1 {
    @apply mb-1 text-xl;
}

.modal {
    margin: 0 !important;
}
